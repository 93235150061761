.navbar {
  background: #262626;
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 100;
  padding-top: 2px;
  box-shadow: 0 2px 4px #00000077;

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-around;
  }

  .header-nav {
    height: 100%;

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      height: 100%;
    }
  }
}

.nav-logo {
  width: max-content;
  height: 40px;

  img {
    width: 40px;
    height: 40px;
    margin: 0 1rem 0 0;
  }

  color: white;
  text-decoration: none;
  font-family: 'Minecraft';
  display: flex;
  flex-direction: row;
  text-align: left;
  text-anchor: start;
  place-content: center;
  font-size: 1.1rem;
  place-items: center;
  font-weight: 100;
  font-style: normal;

  &:hover {
    color: rgb(190, 190, 190);
  }

   &:focus-visible {
    outline: none;
    text-decoration: underline;
  }
}

.navbar-link {
  height: 100%;
  display: flex;
  place-items: center;

  svg, img {
    width: 32px;
    height: 32px;
  }

  img {
    image-rendering: crisp-edges;
    image-rendering: pixelated;
  }
}

@keyframes aaab {
  0% { transform: translate(100%); }
  10% { transform: translate(100%); }
  100% { transform: translate(0); }
}

@keyframes aabb {
  0% { transform: translate(0); }
  10% { transform: translate(0); }
  100% { transform: translate(100%); }
}

.navbar-link-cont {
  display: block;
  color: white;
  padding: 0.2rem 0.6rem;
  height: 100%;
  text-decoration: none;
  font-family: 'Minecraft';
  display: flex;
  place-items: center;
  border: 1px solid transparent;
  border-radius: 0;

  &:hover {
    background: hsla(0, 0%, 100%, 0.2);
    span {
      font-size: 1rem;
      margin-left: 0.3rem;
    }
  }

  span {
    transition: font 300ms, margin 300ms;
    font-size: 0;
    margin-left: 0;
  }

  &:focus-visible {
    outline: none;
    border: 1px solid white;
    span {
      font-size: 1rem;
      margin-left: 0.3rem;
    }
  }
}

.hamburger-btn {
  display: flex;
  place-items: center;
  place-content: center;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0.2rem 0.6rem;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: white;
  }
  
  svg {
    width: 32px;
    height: 32px;
  }

  &:hover {
    background: #ffffff33;
  }

  &:disabled {
    filter: brightness(60%);
  }
}

.hamburger-btn {
  display: none;
}

@media screen and (max-width: 450px) {
  .nav-logo {
    font-size: 0;
  }
}

.hamburger-btn {
  display: flex;
}

.sidebar {
  display: none;
    &.open {
      display: block;
  }
}

.sidebar {
  position: absolute;
  display: none;
  background: #262626;
  right: -100%;
  top: 50px;
  width: 300px;
  height: calc(100vh - 50px);
  transition: transform 200ms ease;
  z-index: -1;
  overflow-y: auto;
  box-shadow: -2px 0 2px 0 #00000077;
  font-family: 'Noto Sans';

  &::-webkit-scrollbar {
    width: 10px;
  }

  .link-container {
    display: flex;
    width: 100%;
    height: max-content;
    padding: 1rem 0;
    overflow-x: hidden;

    h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      font-weight: 100;
      font-size: 1.8rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0;
    }

    h2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      font-weight: 100;
      font-size: 1.45rem;
      margin-left: 1.55rem;
      padding: 1.2rem 0 0;
    }
  }

  &.open {
    display: none;
    right: 0;
  }

  nav {
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;

    h1 {
      color: white;
      font-weight: 100;
      font-size: 2.2rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0 0;
    
      &:first-of-type {
        padding-top: 0;
      }
    }

    h2 {
      color: white;
      font-weight: 100;
      font-size: 2.0rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0 0;
    
      &:first-of-type {
        padding-top: 0;
      }
    }

    h3 {
      color: white;
      font-weight: 100;
      font-size: 1.8rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0 0;
    
      &:first-of-type {
        padding-top: 0;
      }
    }

    h4 {
      color: white;
      font-weight: 100;
      font-size: 1.5rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0.7rem 0;
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    li {
      width: 100%;
      color: white;
    }

    .linkto {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 0.5rem 2rem;
      color: #2bbd28;
      font-size: 0.95rem;
      text-decoration: none;
      border: 1px solid transparent;
      transition-duration: 0.3s;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        border-color: #fff;
      }

      &:hover {
        background: #ffffff33;
        color: #fff;
      }
    }
  }
}

.sidebar {
    display: none;
    &.open {
      display: block;
  }
}

.hide {
  display: none;
}
@import url(./components/dialog/index.scss);
@import url(./components/background/index.scss);

.woolbgBot {
  height: 100px;
  display: block;
  background-position-x: -815px;
  background-repeat: repeat-x!important;
  width: 100%;
  margin-bottom: -1px;
  margin-top: 50px;
}
.economy {
  background: #323232;
  height: 100%;

  .economy-section {
    background: rgb(54, 54, 54) url(../../assets/images/banners/other_early_console_era.jpg);
    background-color: #00000069;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: calc(31vh - 60px);
    display: flex;
    place-content: center;
    place-items: center;
    flex-direction: column;
    text-align: center;
    height: 250px;

    h2 {
      font-family: 'MinecraftFiveBold';
      color: white;
      font-size: 1.9rem;
      text-shadow: 0 4px 0 rgb(48, 48, 48);
      font-weight: 100;
      font-style: normal;
    }
  
    p {
      font-family: 'MinecraftFiveBold';
      color: white;
      text-shadow: 0 3px 0 rgb(48, 48, 48);
      font-weight: 100;
      font-style: normal;
      font-size: 1.2rem;
    }
  }
  
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    height: max-content;
    overflow: hidden;
    height: 100%;
  
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      color: white;
      width: 100%;

      .error {
        display: flex;
        flex-direction: column;
        align-items: center;

        i {
          margin-bottom: 15px;
          font-size: 5rem;
        }

        h1 {
          margin: 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  hr {
    border-color: rgb(146, 146, 146);
    margin: 0.4rem 0;
  }
}
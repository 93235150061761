.sidebar {
  position: absolute;
  display: none;
  background: #262626;
  right: -100%;
  top: 50px;
  width: 300px;
  height: calc(100vh - 50px);
  transition: transform 200ms ease;
  z-index: -1;
  overflow-y: auto;
  box-shadow: -2px 0 2px 0 #00000077;
  font-family: 'Noto Sans';

  &::-webkit-scrollbar {
    width: 10px;
  }

  .container {
    display: flex;
    width: 100%;
    height: max-content;
    padding: 1rem 0;
    overflow-x: hidden;
  }

  &.open {
    display: none;
    right: 0;
  }

  nav {
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;

    h3 {
      font-family: 'MinecraftTen';
      color: white;
      font-weight: 100;
      font-size: 1.8rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0 0;
    
      &:first-of-type {
        padding-top: 0;
      }
    }

    h4 {
      font-family: 'MinecraftTen';
      color: rgb(204, 204, 204);
      font-weight: 100;
      font-size: 1.5rem;
      margin-left: 1.2rem;
      padding: 1.2rem 0 0.7rem 0;
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    li {
      width: 100%;
      color: white;
    }

    a {
      display: block;
      width: 100%;
      padding: 0.5rem 2rem;
      color: #2bbd28;
      font-size: 0.95rem;
      text-decoration: none;
      border: 1px solid transparent;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        border-color: #fff;
      }

      &:hover {
        background: #ffffff33;
        color: #fff;
      }
    }
  }
}

.sidebar {
    display: none;
    &.open {
      display: block;
  }
}
.donate {
  background: #323232;
  height: 100%;

  section {
    background: rgb(54, 54, 54) url(./../../assets/images/banners/1.13_technically_updated_java.jpg);
    background-color: #00000069;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: calc(31vh - 60px);
    display: flex;
    place-content: center;
    place-items: center;
    flex-direction: column;
    text-align: center;
    height: 250px;
    width: 100%;

    h2 {
      font-family: 'MinecraftFiveBold';
      color: white;
      font-size: 1.9rem;
      text-shadow: 0 4px 0 rgb(48, 48, 48);
      font-weight: 100;
      font-style: normal;
    }
  
    p {
      font-family: 'MinecraftFiveBold';
      color: white;
      text-shadow: 0 3px 0 rgb(48, 48, 48);
      font-weight: 100;
      font-style: normal;
      font-size: 1.2rem;
    }
  }
  
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    height: max-content;
    overflow: hidden;
    height: 200%;
  
    .container {
      display: flex;
      gap: 1rem;
      color: white;
      flex-direction: column;
      align-items: center;
      width: 75%;

      h1 {
        margin: 0;
        color: white;
        font-size: 2.2rem;
        padding-bottom: 5px;
      }

      h2 {
        margin: 0;
        color: white;
        font-size: 1.8rem;
        padding-bottom: 4.5px;
      }

      h3 {
        margin: 0;
        color: white;
        font-size: 1.4rem;
        padding-bottom: 4px;
      }

      h4 {
        margin: 0;
        color: white;
        font-size: 1.0rem;
        padding-bottom: 3.5px;
      }

      h5 {
        margin: 0;
        color: white;
        font-size: 0.6rem;
        padding-bottom: 3px;
      }

      h6 {
        margin: 0;
        color: white;
        font-size: 0.2rem;
        padding-bottom: 2.5px;
      }

      li {
        margin: 0;
        color: white;
        padding-bottom: 1.5px;
      }

      a {
        color: white;
        font-size: 1rem;
        text-decoration: none;
        padding-bottom: 1.5px;

        &:hover {
            border: 1px solid white;
        }
      }

      p {
        color: white;
        padding-bottom: 1.5px;
      }

      img {
        object-fit: contain;
        max-width: 100vw;
      }

      .list-table-container {
        text-align: center;
        text-wrap: nowrap;
      
        padding: 1.25%;
        border-radius: 1%;
      
        .tables {
          overflow-x: auto;
          
          table {
            border-collapse: collapse;
            width: 100%;
            border: 0.5px solid;
            thead {
              background: rgba(255, 255, 255, 0);
              tr {
                th {
                  border: 0.5px solid;
                  padding: 1rem;
                }
              }
            }
            tbody {
              background: rgba(255, 255, 255, 0);
              tr {
                td {
                  border: 0.5px solid;
                  padding: 1rem;
                  width: 100%;
                  p {
                    color: white;
                    .faces {
                      width: 25px;
                      height: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      
        .table_footer {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 0.75rem;
          padding: 0 8px;
          flex-direction: row-reverse;
          padding-top: 1.25%;
          color: var(--main-text-color);
      
          .text {
            margin: 0 32px 0 24px;
            font-size: 0.75rem;
            color: var(--main-text-color);
          }
      
          .btn_round {
            height: 36px;
            width: 36px;
            font-size: 0.75rem;
            border-radius: 100%;
            background-color: transparent;
            border-style: none;
            color: var(--main-text-color);
            align-items: center;
            transition: background-color .3s linear, border .3s linear;
      
            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
              transition-duration: 0.3s;
            }
      
            &.disabled {
              color: var(--main-disabled-color);
            }
          }
        }
      }

      .donate-container {
        display: flex;
        height: 321px;
        width: 100%;
        flex-direction: column;
        
        .header {
          width: 100%;
          height: 239px;
          box-sizing: border-box;
          border-radius: .3333333333333333rem .3333333333333333rem 0 0;
          position: relative;
          overflow: hidden;

          img {
            border-radius: .3333333333333333rem .3333333333333333rem 0 0;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .bottom {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          padding: 16px !important;
          height: 50px;

          .avatar {
            margin-right: 1.333333333333333rem;
            width: 40px;
            height: 40px;
            overflow: hidden;
            position: relative;
            background: rgba(0, 0, 0, .05);
            border-radius: 10000px;

            img {
              border-radius: 10000px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
              width: 100%;
            }
          }

          .text {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            flex: 1;

            .name {
              display: flex;
              align-items: center;

              .button-box {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .button {
                  border-radius: 2px;
                  background: linear-gradient(90deg, #946ce6, #7e5fd9);
                  color: #fff;
                  padding: 8px 24px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  align-self: center;
                  white-space: nowrap;
                  cursor: pointer;
                  font-size: 16px;
                  line-height: 1;
                  box-sizing: border-box;
                  position: relative;
                  border: 0;
                }
              }
              
              span {
                color: rgba(255, 255, 255, .95) !important;
                font-size: 16px;
                font-weight: 600;
                line-height: 1.4;
                word-break: break-all;
              }
            }

            .intro {
              .text {
                margin-top: 4px !important;
                color: rgba(255, 255, 255, .9) !important;
                font-size: 12px !important;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }

  hr {
    border-color: rgb(146, 146, 146);
    margin: 0.4rem 0;
  }
}

@media screen and (max-width: 600px) {
  .donate main .container .donate-container {
    width: 100vw;
  }
}
.friends {
  background: #323232;
  height: 100%;

  section {
    background: rgb(54, 54, 54) url(./../../../assets/images/banners/1.15_buzzy_bees_update.jpg);
    background-color: #00000069;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: calc(31vh - 60px);
    display: flex;
    place-content: center;
    place-items: center;
    flex-direction: column;
    text-align: center;
    height: 250px;
    width: 100%;

    h2 {
      font-family: 'MinecraftFiveBold';
      color: white;
      font-size: 1.9rem;
      text-shadow: 0 4px 0 rgb(48, 48, 48);
      font-weight: 100;
      font-style: normal;
    }
  
    p {
      font-family: 'MinecraftFiveBold';
      color: white;
      text-shadow: 0 3px 0 rgb(48, 48, 48);
      font-weight: 100;
      font-style: normal;
      font-size: 1.2rem;
    }
  }
  
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    height: max-content;
    overflow: hidden;
    height: 200%;
  
    .container {
      display: flex;
      gap: 1rem;
      color: white;
      flex-direction: column;
      align-items: flex-start;
      width: 75%;

      h1 {
        margin: 0;
        color: white;
        font-size: 2.2rem;
        padding-bottom: 5px;
      }

      h2 {
        margin: 0;
        color: white;
        font-size: 1.8rem;
        padding-bottom: 4.5px;
      }

      h3 {
        margin: 0;
        color: white;
        font-size: 1.4rem;
        padding-bottom: 4px;
      }

      h4 {
        margin: 0;
        color: white;
        font-size: 1.0rem;
        padding-bottom: 3.5px;
      }

      h5 {
        margin: 0;
        color: white;
        font-size: 0.6rem;
        padding-bottom: 3px;
      }

      h6 {
        margin: 0;
        color: white;
        font-size: 0.2rem;
        padding-bottom: 2.5px;
      }

      li {
        margin: 0;
        color: white;
        padding-bottom: 1.5px;
      }

      a {
        color: white;
      }

      p {
        color: white;
        padding-bottom: 1.5px;
      }

      img {
        object-fit: contain;
        max-width: 100vw;
      }
    }
  }

  hr {
    border-color: rgb(146, 146, 146);
    margin: 0.4rem 0;
  }
}
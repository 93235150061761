.NotFound-container {
  width: 100%;
  height: 90vh;

  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  color: white;

  h2 {
    font-size: 2.2rem;
    padding-bottom: 10px;
  }

  p {
    font-size: 1.1rem;
    padding-bottom: 2rem;
  }
}
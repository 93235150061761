.table-container {
  text-align: center;
  text-wrap: nowrap;

  padding: 1.25%;
  border-radius: 1%;
  
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    border: 0.5px solid;
    thead {
      background: rgba(255, 255, 255, 0);
      tr {
        th {
          border: 0.5px solid;
          padding: 1rem;
        }
      }
    }
    tbody {
      background: rgba(255, 255, 255, 0);
      tr {
        td {
          border: 0.5px solid;
          padding: 1rem;
          width: 100%;
          p {
            color: white;
            .faces {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
}
@font-face {
    font-family: 'Minecraft';
    src: url(../../fonts/Minecraft.ttf);
}
  
@font-face {
    font-family: 'MinecraftFive';
    src: url(../../fonts/MinecraftFive-Regular.ttf);
    font-weight: 100;
    font-style: normal;
}
  
@font-face {
    font-family: 'MinecraftFiveBold';
    src: url(../../fonts/MinecraftFive-Bold.ttf);
}
  
@font-face {
    font-family: 'MinecraftTen';
    src: url(../../fonts/MinecraftTen.ttf);
    font-weight: 100;
    font-style: normal;
}

.boldfonts {
  font-weight: bold;
}

.bolderfonts {
  font-weight: bolder;
}

.lighterfonts {
  font-weight: lighter;
}

.line-through {
  text-decoration:line-through;
}
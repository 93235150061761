.mcui-woolbgTop {
    height: 100px;
    display: block;
    background-position-x: -710px;
    background-repeat: repeat-x!important;
    width: 100%;
    margin-top: -1px;
    margin-bottom: 50px;
  }

.mcui-background-delivery {
    background-image: url(./images/delivery.gif);
}

.mcui-background-wool-green {
    background-image: url(./images/bg-wool-green.png);
}

.mcui-br-blackground-wool-dark {
    height: 75px;
    background-image: url(./images/bg-wool-dark.png);
}

.mcui-background-trans-to-light {
    background-image: url(./images/trans-top-darkwool.png);
}

.woolbgTop {
    height: 100px;
    display: block;
    background-position-x: -710px;
    background-repeat: repeat-x!important;
    width: 100%;
    margin-top: -1px;
    margin-bottom: 50px;
}

.mcui-background-trans-to-dark {
    background-image: url(./images/trans-bottom-darkwool.png);
}

.woolbgBot {
    height: 100px;
    display: block;
    background-position-x: -815px;
    background-repeat: repeat-x!important;
    width: 100%;
    margin-bottom: -1px;
    margin-top: 50px;
}

.mcui-background-wool-light {
    background-image: url(./images/bg-wool-light.png);
}

.mcui-blackground-wool-dark {
    background-image: url(./images/bg-wool-dark.png);
}

.text-black {
    color: var(--dark-text-color);
}
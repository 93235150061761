.fontsize5 {
  font-size:5em
}

.main-section {
  position: relative;
  background: url(https://api.imlazy.ink/mcapi/mcbg.php);
  background-size: cover;
  background-color: #00000090;
  background-blend-mode: multiply;
  background-position: 50%;
  background-repeat: no-repeat;
  height: calc(100vh);
  overflow-y: auto;

  .arrow-down {
    width: 30px;
    height: 30px;
  }

  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  text-align: center;

  h2 {
    padding: 1rem 0;
    text-shadow: 0 2.5px 0 #000000;
    color: white;
    word-spacing: 5px;
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 85%;
    font-weight: 100;
    font-style: normal;
  }

  h3 {
    padding: 1rem 0;
    text-shadow: 0 2.5px 0 #000000;
    color: white;
    word-spacing: 5px;
    font-size: 1.1rem;
    line-height: 1.5rem;
    width: 85%;
    font-weight: 100;
    font-style: normal;
  }

  .logo {
    max-width: 660px; 
    width: 660px;
    margin-bottom: 1.8rem;
  }
}

.arrow-scroll {
  position: absolute;
  bottom: 3rem;

  svg {
    width: 40px;
    height: 40px;
    animation: scrollArrowAnim 1000ms infinite ease;
  }
}

@keyframes scrollArrowAnim {
  0% {
    transform: translateY(-15px);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(-15px);
  }
}

.home-show-sections {
  background: #323232;

  .container {

    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 3rem 0 4rem 0;
    place-items: center;
  }

  .being-built {
    margin: 2rem 0 0 0;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  min-height: 100%;
  color: var(--dark-text-color);

  .row {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;

    padding-right: 15%;
    padding-left: 15%;

    .items {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--main-text-color);
      border-radius:15px;
      padding-right: 8px;
      padding-left: 8px;
      margin: 5px;
      min-width: 33%;
      flex: 1;

      h6 {
        margin-top: 1.2em;
        margin-bottom: .6em;
        font-size: 1.1em;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 6px 6px;

        h4 {
          font-size: 1.4em;
          margin-top: 1.2em;
          margin-bottom: .6em;
          text-wrap: nowrap;
        }
        p {
          margin-top: 1.2em;
          margin-bottom: .6em;
          text-wrap: wrap;
        }
      }
    }
  }
}

.main-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  min-height: 100%;
  color: var(--dark-text-color);

  .about-container {
    .row {
      display: flex;

      padding-right: 15%;
      padding-left: 15%;

      .col-1 {
        padding-right: 20px;
        padding-bottom: 15px;

        h2 {
          margin-bottom: 1.375rem;
        }

        p {
          color: #787976;
          font: 400 1rem/1.25rem "No Sans", sans-serif;
        }

        a {
          color: #787976;
          font: 400 1rem/1.25rem "No Sans", sans-serif;
          text-decoration: none;

          &:hover {
              text-decoration: underline;
          }
        }
      }

      .col-2 {
        display: flex;

        .about-img {
          max-width: 100%;
          object-fit: cover;
          border-radius: 0.375rem;
        }
      }
    }
  }
}

.server-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  min-height: 100%;
  color: var(--main-text-color);

  .row {
    display: flex;

    .card {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      max-width: 18.25rem;
      display: inline-block;
      vertical-align: top;

      margin: 5px;
      margin-bottom: 20px;

      border-radius: 0.375rem;

      min-height: 400px;
      max-height: 400px;
      border-bottom-left-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;

      .img {
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
        max-width: 100%;
        height: auto;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;

        color: var(--dark-text-color);

        padding: 2.25rem 2rem 2.125rem 2rem;
        // border: 1px solid #ebe8e8;
        border: none;
      }
    }
  }
}

.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  color: white;

  .news-container {

    .heading {
      display: flex;
      justify-content: center;
      max-width: 100vw;

      margin-top: 16px!important;
      margin-bottom: 16px!important;

      h2 {
        font-size: 28px;
        margin-left: 6px!important;
        margin-right: 6px!important;
      }
    }

    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      max-width: 100vw;
      width: 100%;
      height: 100%;

      .news-card {
        display: flex;
        flex: 1 0 15%;
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        margin: 5px;

        .news-img {
          border-radius: 0.375rem;
          width: 440px;
          height: 250px;
          object-fit: cover;
          max-width: 100vw;
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .about-container .row {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .main-section .logo {
    max-width: 660px; 
    width: 70%;
  }

  .server-list .row {
    flex-direction: column;
  }
  
  .info .row {
    flex-direction: column;
  }
}

@media screen and (max-height: 700px) and (max-width: 400px) {
  .arrow-scroll {
    display: none;
  }
}

@media screen and (max-height: 600px) {
  .main-section .logo {
    max-width: 70%; 
    margin-bottom: 1.8rem;
  }
  
  .main-section h3 {
    padding: 0;
  }
}

@media screen and (max-height: 520px) {
  .main-section .logo {
    display: none;
  }
}
